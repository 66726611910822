import PropTypes from 'prop-types';
import 'tailwindcss/tailwind.css';
import '../styles/tailwind.css';

const MakaraWeb = ({ Component, pageProps }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <>
    <Component {...pageProps} />
    <div className="p-6 pb-0 text-xs bg-gray-200 text-navy-300">
      <h5 className="pb-4 text-md">
        Argonaut Asset Management LLC. (d/b/a “Makara”) is a wholly-owned
        subsidiary of Betterment Holdings, Inc., and an affiliate of Betterment
        LLC. The above material and content should not be considered to be a
        recommendation. Makara is an investment adviser registered with the US
        Securities and Exchange Commission. Registration as an investment
        adviser does not imply a particular level of skill or training. Makara
        exclusively provides investment advisory services related to investing
        in cryptocurrencies and other digital assets.
      </h5>
      <h5 className="pb-4 text-md">
        Makara is not a broker-dealer, exchange, custodian, or wallet provider,
        and is not intended for frequent trading activity. Investing in digital
        assets is highly speculative and volatile and Makara is only suitable
        for investors who are willing to bear the risk of loss and experience
        sharp drawdowns.
      </h5>
      <h5 className="pb-4 text-md">
        Cryptocurrency is not legal tender and is not backed by the government.
        Cryptocurrency, (including but not limited to bitcoin and ethereum, and
        stablecoins such as USDC), is not subject to Federal Deposit Insurance
        Corporation (“FDIC”) or Securities Investor Protection Corporation
        protections (“SIPC”).
      </h5>
      <h5 className="pb-4 text-md">
        Past performance is not a guarantee of future results. For more complete
        disclosures and information about how Makara works, please visit our{' '}
        <a
          className="font-medium text-pink-500 underline"
          href="https://makara.com/legal/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>.
      </h5>
      <p className="pb-4 text-lg text-black-300" />
    </div>
  </>
);
/* eslint-enable */
MakaraWeb.propTypes = {
  Component: PropTypes.elementType.isRequired,
  /* eslint-disable react/forbid-prop-types */
  pageProps: PropTypes.object.isRequired,
  /* eslint-enable */
};

export default MakaraWeb;
